<template>
  <Toast position="top-right" width="400" />
  <div class="
        surface-0
        flex
        align-items-center
        justify-content-center
        min-h-screen min-w-screen
        overflow-hidden
        gradient
      " >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 50%">
      <div class="col-12 xl:col-6" style="
            border-radius: 56px;
            padding: 0.3rem;
            background: linear-gradient(
              180deg,
              var(--primary-color),
              rgba(33, 150, 243, 0) 30%
            );
          ">
        <div class="h-full w-full m-0 py-7 px-4" style="
              border-radius: 53px;
              background: linear-gradient(
                180deg,
                var(--surface-50) 38.9%,
                var(--surface-0)
              );
            ">
          <div class="text-center mb-5">
            <div class=" mb-5">
              <Image src="/app/front/images/logotipo.svg" width="230" alt="Image" style="margin-bottom: 20px;" />
            </div>
            <b class=" text-1xl mb-5 mt-5">XML - Gestão de Faturas</b>
     
          </div>
          <hr class="mb-5"/>
          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
            <InputText id="email1" v-model="email" type="email" class="w-full mb-3" placeholder="Email"
              style="padding: 1rem" />

            <label for="password1" class="block text-900 font-medium text-xl mb-2">Senha</label>
            <Password id="password1" v-model="password" placeholder="Senha" class="w-full mb-3" inputClass="w-full"
              inputStyle="padding:1rem"></Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)"
                @click="this.recuperarSenha = true">Esqueceu a Senha?</a>
            </div>
            <Button label="Acessar" class="w-full p-3 text-xl" @click="login()"></Button>
            <hr />
            <div class="text-center mt-5">

            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <Dialog v-model:visible="recuperarSenha" :style="{ width: '450px' }" header="Recuperar Senha" :modal="true"
    class="p-fluid">
    <div class="field">
      <label for="area">Seu E-mail</label>
      <InputText id="area" type="email" v-model.trim="recuperar_email" required="true" autofocus
        :class="{ 'p-invalid': submitted && !recuperar_email }" />
      <small class="p-invalid" v-if="submitted && !recuperar_email">E-mail é obrigatório.</small>
    </div>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
        @click="this.recuperarSenha = false" />
      <Button label="Enviar" icon="pi pi-check" class="p-button-success p-button-sm" id="bt-recuperar"
        @click="recuperarSenhaUrl" />
    </template>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {

      recuperarSenha: false,
      recuperar_email: "",
      email: "",
      password: "",
      checked: false,
    };
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  mounted() {

  },
  methods: {
    dash() { },

    recuperarSenhaUrl() {
      let bt = document.getElementById('bt-recuperar');
      bt.disabled = true;
      bt.innerHTML = "Aguarde...";
      let self = this;
      let data = {
        email: this.recuperar_email,
      };
      this.axios
        .post("/recuperar", data)
        .then(() => {
          self.$toast.add({
            severity: "success",
            summary: "Recuperação de Senha",
            detail: "Caso seu e-mail esteja cadastrado, receberá um link para recuperar sua senha!",
            life: 5000,
            posistion: 'top-left'
          });
          bt.disabled = false;
          bt.value = "Enviar";
          this.recuperarSenha = false
        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });

          bt.disabled = false;
          bt.innerHTML = "Enviar";
        });
    },


    login() {
      let self = this;
      let data = {
        password: this.password,
        email: this.email,
      };
      this.axios
        .post("/auth/login", data)
        .then((response) => {
          self.$toast.add({
            severity: "success",
            summary: "Sucesso!!",
            detail: "Bem vindo!!",
            life: 3000,
          });
    

          localStorage.setItem("token", response.data.authorization.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.$router.push("/dashboard");

        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2,0,36);
background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(124,118,232,1) 100%);
}
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>